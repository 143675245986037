<template>
  <div id="orderreturn-list">
    <v-card>
      <v-card-title>返品キャンセル一覧</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="productName"
            :items="Products"
            placeholder="商品名"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="dateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                placeholder="注文日From"
                dense
                hide-details
                outlined
                readonly
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="startDate" @input="dateStartMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                placeholder="注文日To"
                dense
                outlined
                readonly
                hide-details
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="dateEndMenu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="search"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :to="{ name: 'order-create', params: { query: this.$route.query } }"
          >
            <v-icon size="17" class="me-1">
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新規</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mb-4 me-3"
          >
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="tableList"
        sort-by
        class="elevation-1"
        :search="search"
      >
        <template v-slot:[`item.ord_id`]="{ item }">
          <a
            href="javascript:void(0);"
            @click="pushOrderHistory(item)"
          >{{ item.ord_id }}</a>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a
                v-bind="attrs"
                href="javascript:void(0);"
                :style="item.mbr_status === 9 ? 'text-decoration: line-through red; color:#696969' : 'text-decoration: underline; color:#696969' "
                v-on="on"
              >{{ item.mbr_sei }} {{ item.mbr_mei }}</a>
            </template>
            <span v-if="(item.mbr_sei || item.mbr_mei) ? true : false">{{ item.mbr_sei }}   {{ item.mbr_mei }}</span><br v-if="(item.mbr_sei || item.mbr_mei) ? true : false">
            <span v-if="(item.mbr_sei_kana || item.mbr_mei_kana) ? true : false">{{ item.mbr_sei_kana }}   {{ item.mbr_mei_kana }}</span><br v-if="(item.mbr_sei_kana || item.mbr_mei_kana) ? true : false">
            <span v-if="item.mbr_zip ? true : false">{{ item.mbr_zip }}</span><br v-if="item.mbr_zip ? true : false">
            <span v-if="(item.mbr_address1 || item.mbr_address2) ? true : false">{{ item.mbr_address1 }}{{ item.mbr_address2 }}</span><br v-if="(item.mbr_address1 || item.mbr_address2) ? true : false">
            <span v-if="item.mbr_email ? true : false">{{ item.mbr_email }}</span><br v-if="item.mbr_email ? true : false">
            <span v-if="item.mbr_mobile ? true : false">{{ item.mbr_mobile }}</span><br v-if="item.mbr_mobile ? true : false">
          </v-tooltip>
        </template>
        <template v-slot:[`item.order_dt`]="{ item }">
          <div>
            <span>{{ item.order_dt }}</span>
          </div>
        </template>
        <template v-slot:[`item.os_desc`]="{ item }">
          <div>
            {{ item.os_desc }}
          </div>
        </template>
        <template v-slot:[`item.order_total`]="{ item }">
          <label>{{ item.order_total ? item.order_total.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : "" }}</label>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row v-if="!item.processed">
            <v-col cols="6">
              <!-- <v-col style="padding-right: 10px" cols="6"> -->
              <v-icon class="mr-2" small @click="editItem(item)">
                {{ icons.mdiPencil }}
              </v-icon>
            </v-col>
            <!-- <v-col style="padding-left: 10px" cols="6">
              <delete-form :icon="icons.mdiDelete" :crushed-id="item.crushed_id"></delete-form>
            </v-col> -->
          </v-row>
        </template>
        <template v-slot:[`item.order_code`]="{ item }">
          <v-tooltip v-if="item.order_code" bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              > {{ item.order_code }}</span>
            </template>
            <span>{{ item.type }}<br />{{ item.product_name }}<br />{{ item.price }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'
import {
  mdiCalendar,
  mdiMagnify,
  mdiPencil,
  mdiDelete,
  mdiPlus,
  mdiExportVariant,
} from '@mdi/js'

// import DeleteForm from '@/views/bimi/product/item/ProductsDelete.vue'

export default {
  components: {
    // DeleteForm,
  },
  data: () => ({
    startDate: '',
    endDate: '',
    dateEndMenu: '',
    dateStartMenu: '',
    firstName: '',
    lastName: '',
    productName: '',
    arrBfo: [],
    search: '',
    tableList: [],
    Products: [],
    icons: {
      mdiCalendar,
      mdiMagnify,
      mdiPencil,
      mdiDelete,
      mdiPlus,
      mdiExportVariant,
    },
    headers: [
      {
        text: '注文ID',
        value: 'ord_id',
        align: 'left',
        width: '8%',
        fixed: true,
      },
      {
        text: '注文番号',
        value: 'order_no',
        align: 'left',
        width: '10%',
        fixed: true,
      },
      {
        text: '会員氏名',
        align: 'left',
        sortable: true,
        value: 'name',
        width: '10%',
        fixed: true,
      },
      {
        text: '商品名',
        value: 'product_name',
        width: '27%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '注文総額',
        value: 'order_total',
        width: '10%',
        align: 'right',
        sortable: true,
        fixed: true,
      },
      {
        text: '注文日',
        value: 'order_dt',
        width: '20%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '注文状態',
        value: 'os_desc',
        width: '10%',
        align: 'center',
        sortable: true,
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '5%',
        align: 'right',
        fixed: true,
      },
      {
        text: 'メールアドレス',
        value: 'mbr_email',
        align: ' d-none',
      },
      {
        text: '姓',
        value: 'mbr_sei',
        align: ' d-none',
      },
      {
        text: '名',
        value: 'mbr_mei',
        align: ' d-none',
      },
      {
        text: 'セイ',
        value: 'mbr_sei_kana',
        align: ' d-none',
      },
      {
        text: 'メイ',
        value: 'mbr_mei_kana',
        align: ' d-none',
      },
      {
        text: '電話番号',
        value: 'mbr_mobile',
        align: ' d-none',
      },
    ],
  }),
  computed: {
    ...mapState('orderStore', ['CanceledList', 'orderStatusDesc', 'orderStatusMaster']),
    ...mapState('productStore', ['ProductList']),
  },
  watch: {
  },
  created() {
    this.loadQuery()
    this.changeRouteQuery()
    this.loadData()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('orderStore', ['loadCanceledList', 'loadOrderStatus']),
    ...mapActions('productStore', ['loadProductList']),
    pushOrderHistory(item) {
      this.$router.push({
        name: 'order-history',
        params: {
          id: item.ord_id,
        },
      })
    },
    loadData() {
      // this.setOverlayStatus(true)
      // this.loadOrderStatus(this.$route.query).then(() => {
      // })
      // this.loadProductList(this.$route.query).then(() => {
      //   this.setOverlayStatus(false)
      //   this.getProductItem()
      // })
      // this.loadCanceledList(this.$route.query).then(() => {
      //   this.tableList = [...this.CanceledList]
      //   this.setOverlayStatus(false)
      // })
    },

    editItem(item) {
      console.log(item)
    },

    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({
        path: this.$route.path, query,
      })
    },

    loadQuery() {
    },

    getProductItem() {
      this.Products = this.ProductList.map(x => {
        const obj = x.product_name

        return obj
      })
    },
  },
}
</script>
<style>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
</style>
